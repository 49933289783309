<template>
  <div class="home">
    <div class="banner"><img src="../assets/images/banner1.jpg" alt="" /></div>
    <div class="current-position">
      <el-breadcrumb>
        <el-breadcrumb-item :to="{ path: '/' }"
          ><span class="icon-home"></span>首页</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/exam' }">考试</el-breadcrumb-item>
        <el-breadcrumb-item>{{ licence_name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="subject-box" v-if="questionIndex < 0" v-loading="true"></div>
    <div class="subject-box" v-if="questionIndex >= 0">
      <el-row>
        <el-col :span="5" class="text-content-left">
          <div class="examinee-info">
            <h2>考生信息</h2>
            <p><img src="../assets/images/userpic.jpg" alt="" /></p>
            <p>
              考生姓名：<span>{{ this.real_name }}</span>
            </p>
            <p>考试题数：{{ this.total }}题</p>
            <p>考试时间：{{ this.exam_time }}分钟</p>
            <p>
              合格标准：满分{{ this.perfectScore }}分{{ this.pass_score }}及格
            </p>
          </div>
          <div class="countdown-box">
            <h2>考试时间</h2>
            <p id="timer" class="style colorDefinition size_sm">
              {{ this.m }}:{{ this.s }}
            </p>
          </div>
        </el-col>
        <el-col :span="19">
          <div class="text-content-right">
            <div class="topic-info">
              <div class="topic">
                <p>
                  <el-tag type="success">
                    {{ this.question.question_type }}
                  </el-tag>
                  {{ questionIndex + 1 }}/{{ this.total }}、{{ question.title }}
                  <span style="color:#aaa;font-size:12px;">
                    ({{ question.chapter_id }}.{{ question.chapter_name }})
                  </span>
                </p>
                <el-button
                  size="medium"
                  icon="el-icon-star-off"
                  @click="collect(question.question_id, questionIndex)"
                >
                  收藏
                </el-button>
              </div>
              <div class="answer">
                <div
                  class="answer-item"
                  v-if="this.question.question_type == '多选'"
                >
                  <el-checkbox-group v-model="checkedCities">
                    <p v-for="item in question.items" :key="item.sign">
                      <span v-show="showAnswer">
                        <span
                          class="icon-cancel"
                          v-if="
                            question.answer
                              .toString()
                              .split(',')
                              .includes(characterData[item.sign - 1]) == false
                          "
                        >
                        </span>
                        <span
                          class="icon-checkmark1"
                          v-if="
                            question.answer
                              .toString()
                              .split(',')
                              .includes(characterData[item.sign - 1]) == true
                          "
                        >
                        </span>
                      </span>
                      <!--只保留正确样式-->
                      <span v-show="showAnswer2">
                        <span
                          class="icon-checkmark1"
                          v-if="
                            question.answer
                              .toString()
                              .split(',')
                              .includes(characterData[item.sign - 1]) == true
                          "
                        >
                        </span>
                        <span
                          style="margin-left:16px"
                          v-if="
                            question.answer
                              .toString()
                              .split(',')
                              .includes(characterData[item.sign - 1]) == false
                          "
                        >
                        </span>
                      </span>
                      <el-checkbox :label="characterData[item.sign - 1]">
                        {{ characterData[item.sign - 1] }}：{{
                          item.item_title
                        }}
                      </el-checkbox>
                    </p>
                  </el-checkbox-group>
                  <el-button type="primary" @click="checkBoxSubmit()"
                    >确认答案</el-button
                  >
                </div>
                <div
                  class="answer-item"
                  v-if="this.question.question_type != '多选'"
                >
                  <p
                    style="cursor:pointer"
                    v-for="item in question.items"
                    :key="item.sign"
                    @click="
                      selectAnswer(
                        characterData[item.sign - 1],
                        question.question_id
                      )
                    "
                  >
                    <span v-show="showAnswer">
                      <span
                        class="icon-cancel"
                        v-if="characterData[item.sign - 1] != question.answer"
                      >
                      </span>
                      <span
                        class="icon-checkmark1"
                        v-if="characterData[item.sign - 1] == question.answer"
                      >
                      </span>
                    </span>
                    <!--只保留正确样式-->
                    <span v-show="showAnswer2">
                      <span
                        class="icon-checkmark1"
                        v-if="characterData[item.sign - 1] == question.answer"
                      >
                      </span>
                      <span
                        style="margin-left:21px"
                        v-if="characterData[item.sign - 1] != question.answer"
                      >
                      </span>
                    </span>
                    {{ characterData[item.sign - 1] }}：{{ item.item_title }}
                  </p>
                </div>
                <div class="media">
                  <el-row v-if="question.image_url" class="thumb">
                    <el-col :offset="1" :span="20">
                      <a
                        :href="tikuImageUrl + question.image_url"
                        target="_blank"
                      >
                        <img
                          v-bind:src="tikuImageUrl + question.image_url"
                          alt=""
                          height="200px"
                        />
                      </a>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
            <div class="questions-info">
              <p class="type">
                {{ this.question.question_type }}，请选择您认为正确的答案！
              </p>
              <div class="btn-bar">
                <el-button
                  type="primary"
                  v-if="questionIndex == 0"
                  disabled
                  @click="back()"
                  >上一题</el-button
                >
                <el-button
                  type="primary"
                  v-else
                  :disabled="this.back_use"
                  @click="back()"
                  >上一题</el-button
                >
                <el-button
                  type="primary"
                  v-if="questionIndex == this.total - 1"
                  disabled
                  @click="next()"
                  >下一题</el-button
                >
                <el-button
                  type="primary"
                  v-else
                  :disabled="this.next_use"
                  @click="next()"
                  >下一题</el-button
                >
                <!-- <el-checkbox style="margin-left:24px" v-model="checked">答对自动下一题</el-checkbox> -->
                <div class="msg-bar">
                  <span>答对：{{ this.rightNum }}题</span>
                  <span>答错：{{ this.wrongNum }}题</span>
                </div>
                <el-button
                  type="success"
                  size="medium"
                  class="right"
                  @click="submitExam()"
                  >提交考试</el-button
                >
              </div>
              <div class="break-down-box" v-show="showDescription">
                <h2><span>试题详解</span></h2>
                <p>正确答案：{{ question.answer }}</p>
                <p>
                  难度：
                  <el-rate
                    v-model="question.difficulty"
                    disabled
                    show-text
                    :texts="texts"
                  ></el-rate>
                </p>
                <p>知识点：{{ question.skill }}</p>
                <p>试题解析：{{ question.analysis }}</p>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {
  questioninfo,
  addcollect,
  addwrong,
  saverecord,
  getexam,
  examsubmit
} from '../api/tiku'
import { getSubject } from '../api/courses'
import {
  getKsCorrectAnswer,
  getKsIsSelect,
  getKsQuestionId,
  getKsWrongAnswer,
  getResult,
  setKsCorrectAnswer,
  setKsIsSelect,
  setKsLicenceId,
  setKsQuestionId,
  setKsSubjectId,
  setKsWrongAnswer,
  removeKsIsSelect,
  removeKsWrongAnswer,
  removeKsCorrectAnswer,
  removeKsQuestionId,
  setKscollectQuestionId
} from '../utils/auth'
import { tikuImageUrl } from '@/settings'
export default {
  name: 'Home',
  data() {
    return {
      licence_name: '',
      rightNum: 0,
      wrongNum: 0,
      checked: true,
      tikuImageUrl,
      showDescription: false,
      question: {
        question_id: 0,
        title: '',
        items: [],
        image_url: '',
        answer: '',
        difficulty: 0,
        analysis: '',
        skill: '',
        chapter_id: '',
        chapter_name: '',
        licence_id: 2,
        subject_id: 1,
        is_vip: false,
        is_exam: true
      },
      questionList: [],
      questionIndex: -1,
      characterData: ['A', 'B', 'C', 'D', 'E'],
      texts: ['简单', '较易', '一般', '较难', '困难'],
      total: 0,
      showAnswer: false,
      showAnswer2: false,
      isSelect: [],
      user_id: '',
      back_use: false,
      next_use: false,
      wrongAnswer: [],
      correctAnswer: [],
      checkedCities: [],
      is_true: false,
      exam_time: 0,
      pass_score: 0,
      real_name: '',
      paper_id: '',
      time: 0,
      h: 0,
      m: 0,
      s: 0,
      timer: null,
      single_score: 0,
      multi_score: 0,
      justify_score: 0,
      score: 0,
      start_time: 0,
      end_time: 0,
      no_questions: [], // 未答题的数组
      is_use: false, // 是否使用no_questions
      is_ksloading: false, //是否有暂停考试弹窗
      perfectScore: 0,
      wrongscore: 0,
      is_passloading: false
    }
  },
  created() {
    this.screenClick()
    this.start_time = Math.round(new Date() / 1000)
    this.licence_id = this.$route.query.licence_id
    this.subject_id = this.$route.query.subject_id
    this.licence_name = this.$route.query.licence_name
    if (getResult() === undefined) {
      this.user_id = ''
    } else {
      this.real_name = JSON.parse(getResult()).real_name
      this.user_id = JSON.parse(getResult()).userid
    }
    if (this.subject_id == '') {
      getSubject({ licence_id: this.licence_id }).then(res => {
        if (res.code === 0) {
          this.subject_id = res.result.subject_list[0].subject_id
          this.getList(this.licence_id, this.subject_id)
        }
      })
    } else {
      this.getList(this.licence_id, this.subject_id)
    }
  },
  methods: {
    /* 是否为全屏 */
    screenClick() {
      var winWidth = window.outerWidth
      var winHeight = window.outerHeight
      var screenWidth = window.screen.availWidth
      var screenHeight = window.screen.availHeight
      // alert(winWidth+","+screenWidth+","+winHeight+","+screenHeight)
      if (winWidth >= screenWidth && winHeight >= screenHeight) {
        // console.log('最大化')
        // alert("最大化")1550,1536,838,824
        clearInterval(this.timer)
        this.countTime()
      } else {
        // alert("不是最大化"+winWidth+","+winHeight+","+screenWidth+","+screenHeight)
        clearInterval(this.timer)
        if (this.is_ksloading == false) {
          this.is_ksloading = true
          this.timer = null
          this.$confirm('考试已暂停，您是否继续?', '提示', {
            confirmButtonText: '是',
            cancelButtonText: '否',
            type: 'warning',
            showClose: false,
            closeOnClickModal: false
          })
            .then(() => {
              // console.log("不是最大化"+winWidth+","+winHeight+","+screenWidth+","+screenHeight)
              this.is_ksloading = false
              this.countTime()
            })
            .catch(() => {
              this.$router.push({ path: '/exam' })
            })
        }
      }
    },
    //上一题
    back() {
      this.checkedCities = []
      if (this.questionIndex > 0) {
        this.next_use = false
        this.questionIndex--
      } else {
        this.back_use = true
      }
    },
    //下一题
    next() {
      this.checkedCities = []
      // 如果使用未答题的数组
      if (this.is_use) {
        if (this.no_questions.length > 0) {
          this.questionIndex = this.no_questions[0]
          return
        } else {
          this.$alert('恭喜你，已答完所有题目，请提交考试', '提示', {
            confirmButtonText: '确定',
            type: 'warning',
            showClose: false,
            closeOnClickModal: false,
            callback: action => {
              this.submitExam()
            }
          })
        }
      } else {
        if (
          this.questionIndex >= 0 &&
          this.questionIndex < this.questionList.length - 1
        ) {
          this.back_use = false
          this.questionIndex++
        } else {
          this.next_use = true
        }
      }
    },
    //获取列表
    getList(licence_id, subject_id) {
      let params = {
        licence_id: licence_id,
        subject_id: subject_id
      }
      getexam(params).then(res => {
        if (res.code == 0) {
          this.questionList = res.data.question_list.split(',')
          for (let i = 0; i < res.data.question_type.length; i++) {
            //单
            if (res.data.question_type[i].question_type == 1) {
              this.perfectScore += parseFloat(res.data.rule.single_score)
            }
            //多
            if (res.data.question_type[i].question_type == 2) {
              this.perfectScore += parseFloat(res.data.rule.multi_score)
            }
            //判
            if (res.data.question_type[i].question_type == 3) {
              this.perfectScore += parseFloat(res.data.rule.justify_score)
            }
          }
          this.total = res.data.question_list.split(',').length
          this.exam_time = res.data.rule.exam_time / 60
          this.pass_score = res.data.rule.pass_score
          this.paper_id = res.data.paper_id
          this.time = res.data.rule.exam_time - 1
          this.single_score = parseFloat(res.data.rule.single_score)
          this.multi_score = parseFloat(res.data.rule.multi_score)
          this.justify_score = parseFloat(res.data.rule.justify_score)
          this.questionIndex = 0
          // this.countTime()
        } else {
          this.questionIndex = -1
        }
      })
    },
    //选择答案
    selectAnswer(answer) {
      // this.isSelect =
      //   getKsIsSelect() !== undefined ? JSON.parse(getKsIsSelect()) : []
      // 答过的题ID加入数组中
      if (this.isSelect.indexOf(this.questionList[this.questionIndex]) == -1) {
        this.isSelect.push(this.questionList[this.questionIndex])
      } else {
        return
      }
      setKsIsSelect(this.isSelect)
      var is_true = false
      if (answer == this.question.answer) {
        is_true = true
      } else {
        is_true = false
      }
      this.judge(is_true)
    },
    //回答正确错误处理
    judge(is_true) {
      // 如果使用的是未答题数组，则将当前答过的题从数组中删除
      if (this.is_use) {
        this.no_questions.splice(
          this.no_questions.indexOf(this.questionIndex),
          1
        )
      }
      let arr = []
      if (this.questionIndex == this.questionList.length - 1) {
        Array.prototype.diff = function(a) {
          return this.filter(function(i) {
            return a.indexOf(i) < 0
          })
        }
        this.no_questions = this.questionList.diff(this.isSelect)
        if (this.no_questions.length > 0) {
          for (let i = 0; i < this.questionList.length; i++) {
            for (let s = 0; s < this.no_questions.length; s++) {
              if (this.no_questions[s] == this.questionList[i]) {
                arr.push(i)
              }
            }
          }
          // arr = [0,2];
          // 将排在最后面的key反转到前面
          this.no_questions = arr
          this.questionIndex = this.no_questions[0]
          this.is_use = true
        } else {
          this.is_use = false
        }
      }

      if (is_true) {
        if (this.question.question_type == '单选') {
          this.score += this.single_score
        } else if (this.question.question_type == '多选') {
          this.score += this.multi_score
        } else if (this.question.question_type == '判断') {
          this.score += this.justify_score
        }
        // 是否勾选答对下一题
        if (this.checked == true) {
          //答对push到correctAnswer数组
          this.correctAnswer =
            getKsCorrectAnswer() !== undefined
              ? JSON.parse(getKsCorrectAnswer())
              : []
          if (
            this.correctAnswer.indexOf(this.questionList[this.questionIndex]) ==
            -1
          ) {
            this.correctAnswer.push(this.questionList[this.questionIndex])
            setKsCorrectAnswer(this.correctAnswer)
          }
          this.rightNum++
          this.showAnswer = false
          this.showAnswer2 = true
          // 如果使用未答题的数组
          if (this.is_use) {
            if (this.no_questions.length > 0) {
              this.questionIndex = this.no_questions[0]
              return
            } else {
              this.$alert('恭喜你，已答完所有题目，请提交考试', '提示', {
                confirmButtonText: '确定',
                type: 'warning',
                showClose: false,
                closeOnClickModal: false,
                callback: action => {
                  this.submitExam()
                }
              })
            }
          } else {
            this.next()
          }
        } else {
          this.showDescription = true
        }
      } else {
        // 回答错误
        if (this.question.question_type == '单选') {
          this.wrongscore += this.single_score
        } else if (this.question.question_type == '多选') {
          this.wrongscore += this.multi_score
        } else if (this.question.question_type == '判断') {
          this.wrongscore += this.justify_score
        }
        //答错push到wrongAnswer数组
        this.wrongAnswer =
          getKsWrongAnswer() !== undefined ? JSON.parse(getKsWrongAnswer()) : []
        if (
          this.wrongAnswer.indexOf(this.questionList[this.questionIndex]) == -1
        ) {
          this.wrongAnswer.push(this.questionList[this.questionIndex])
          setKsWrongAnswer(this.wrongAnswer)
        }
        this.wrongNum++
        this.showAnswer = true
        this.showAnswer2 = false
        this.showDescription = true
        if (getResult() != undefined) {
          let params = {
            user_id: this.user_id,
            question_id: this.question.question_id
          }
          addwrong(params).then(res => {
            // console.log(res)
          })
        }
        // 如果使用未答题的数组
        if (this.is_use) {
          if (this.no_questions.length <= 0) {
            this.$alert('恭喜你，已答完所有题目，请提交考试', '提示', {
              confirmButtonText: '确定',
              type: 'warning',
              showClose: false,
              closeOnClickModal: false,
              callback: action => {
                this.submitExam()
              }
            })
          }
        }
      }
      if (this.is_passloading == false) {
        if (this.perfectScore - this.wrongscore < this.pass_score) {
          clearInterval(this.timer)
          this.$confirm('您已不及格，是否继续?', '提示', {
            confirmButtonText: '是',
            cancelButtonText: '否',
            type: 'warning',
            showClose: false,
            closeOnClickModal: false
          })
            .then(() => {
              this.is_passloading = true
              this.countTime()
            })
            .catch(() => {
              this.is_passloading = false
              this.submitExam()
            })
        }
      }
      if (this.isSelect.length == this.questionList.length) {
        this.$alert('恭喜你，已答完所有题目，请提交考试', '提示', {
          confirmButtonText: '确定',
          type: 'warning',
          showClose: false,
          closeOnClickModal: false,
          callback: action => {
            this.submitExam()
          }
        })
      }
    },
    //添加到收藏
    collect(question_id, questionIndex) {
      if (this.user_id == '') {
        setKsLicenceId(this.licence_id)
        setKsSubjectId(this.subject_id)
        setKsQuestionId(questionIndex)
        this.$confirm('请您登录后再进行收藏', '提示', {
          confirmButtonText: '我知道了',
          type: 'warning'
        }).then(() => {
          location.href = '#/login'
        })
      } else {
        let params = {
          user_id: this.user_id,
          question_id: question_id
        }
        addcollect(params).then(res => {
          if (res.code == 0) {
            this.$message.success('收藏成功')
          } else {
            this.$message.error(res.message)
            return
          }
        })
      }
    },
    keepRecords(questionIndex) {
      if (this.user_id == '') {
        setKsLicenceId(this.licence_id)
        setKsSubjectId(this.subject_id)
        setKsIsSelect(this.isSelect)
        setKsWrongAnswer(this.wrongAnswer)
        setKsCorrectAnswer(this.correctAnswer)
        setKsQuestionId(questionIndex)
        this.$confirm('请您登录后再进行保存记录', '提示', {
          confirmButtonText: '我知道了',
          type: 'warning'
        }).then(() => {
          location.href = '#/login'
        })
      } else {
        let params = {
          user_id: this.user_id,
          licence_id: this.licence_id,
          subject_id: this.subject_id,
          record_type: 1,
          record_no: questionIndex + 1
        }
        saverecord(params).then(res => {
          if (res.code == 0) {
            this.$message.success('记录成功')
          } else {
            this.$message.error(res.message)
            return
          }
        })
      }
    },
    checkBoxSubmit() {
      this.isSelect =
        getKsIsSelect() !== undefined ? JSON.parse(getKsIsSelect()) : []
      if (this.isSelect.indexOf(this.questionList[this.questionIndex]) == -1) {
        this.isSelect.push(this.questionList[this.questionIndex])
      } else {
        return
      }
      setKsIsSelect(this.isSelect)
      let answerArr = []
      var is_true = false
      answerArr = this.question.answer.toString().split(',')
      if (this.checkedCities.sort().toString() == answerArr.sort().toString()) {
        is_true = true
      } else {
        is_true = false
      }
      this.judge(is_true)
    },
    //提交考试
    submitExam() {
      clearInterval(this.timer)
      let is_pass = 0
      if (this.score >= this.pass_score) {
        is_pass = 1
      } else {
        is_pass = 0
      }
      let params = {
        licence_id: this.licence_id,
        subject_id: this.subject_id,
        score: this.score,
        is_pass: is_pass,
        start_time: this.start_time,
        end_time: Math.round(new Date() / 1000) - 1
      }
      examsubmit(params).then(res => {
        if (res.code == 0) {
          this.$router.push({ path: '/results' })
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    //倒计时
    countTime() {
      // this.start_time = Math.round(new Date() / 1000)
      this.timer = setInterval(() => {
        if (this.time >= 0) {
          // this.h = Math.floor(this.time / 60 / 60 % 24);
          this.m = Math.floor((this.time / 60) % 60)
          this.s = Math.floor(this.time % 60)
          if (this.s < 10) {
            this.s = '0' + this.s
          }
          if (this.m < 10) {
            this.m = '0' + this.m
          }
          // if(this.h < 10) {
          //   this.h = '0' + this.h;
          // }
          this.time -= 1
        } else {
          clearInterval(this.timer)
          this.timer = null
          this.submitExam()
        }
      }, 1000)
    },
    setpause() {
      if (document.hidden) {
        document.title = '用户离开了'
        clearInterval(this.timer)
        this.timer = null
      } else {
        document.title = '用户回来了'
      }
    }
  },
  watch: {
    questionIndex: function() {
      // if (getKsQuestionId() !== undefined) {
      //   this.questionIndex = Number(getKsQuestionId())
      //   removeKsQuestionId()
      // }
      // if (getKsIsSelect()() !== undefined) {
      //   this.isSelect = getKsIsSelect()
      //   removeKsIsSelect()
      // }
      // if (getKsWrongAnswer() !== undefined) {
      //   this.wrongAnswer = getKsWrongAnswer()
      //   removeKsWrongAnswer()
      // }
      // if (getKsCorrectAnswer() !== undefined) {
      //   this.correctAnswer = getKsCorrectAnswer()
      //   removeKsCorrectAnswer()
      // }
      // this.isSelect =
      //   getKsIsSelect() !== undefined ? JSON.parse(getKsIsSelect()) : []
      // this.wrongAnswer =
      //   getKsWrongAnswer() !== undefined ? JSON.parse(getKsWrongAnswer()) : []
      // this.correctAnswer =
      //   getKsCorrectAnswer() !== undefined
      //     ? JSON.parse(getKsCorrectAnswer())
      //     : []
      if (this.questionIndex >= 0) {
        questioninfo({ id: this.questionList[this.questionIndex] }).then(
          response => {
            if (response.code === 0) {
              let question = response.data
              let answerLabel = []
              question.answer
                .toString()
                .split(',')
                .forEach(function(item) {
                  answerLabel.push(String.fromCharCode(64 + parseInt(item)))
                })
              let typeName = ''
              if (question.question_type == 1) {
                typeName = '单选'
              }
              if (question.question_type == 2) {
                typeName = '多选'
              }
              if (question.question_type == 3) {
                typeName = '判断'
              }
              this.question = {
                question_id: question.question_id,
                question_type: typeName,
                title: question.title,
                items: question.items,
                licence_id: question.licence_id,
                subject_id: question.subject_id,
                province_id: question.province_id,
                city_id: question.city_id,
                image_url: question.image_url,
                answer: answerLabel.join(','),
                difficulty: question.difficulty,
                analysis: question.analysis,
                skill: question.skill,
                chapter_id: question.chapter_id,
                chapter_name: question.chapter_name,
                is_vip: question.is_vip == 1 ? true : false,
                is_exam: question.is_exam == 1 ? true : false
              }
              if (
                this.isSelect.indexOf(this.questionList[this.questionIndex]) ==
                -1
              ) {
                this.showAnswer = false
                this.showAnswer2 = false
                this.showDescription = false
              } else {
                if (
                  this.wrongAnswer.indexOf(
                    this.questionList[this.questionIndex]
                  ) != -1
                ) {
                  this.showAnswer = true
                  this.showAnswer2 = false
                  this.showDescription = true
                }
                if (
                  this.correctAnswer.indexOf(
                    this.questionList[this.questionIndex]
                  ) != -1
                ) {
                  this.showAnswer = false
                  this.showAnswer2 = true
                  this.showDescription = true
                }
              }
            }
          }
        )
      }
    }
  },
  components: {},
  mounted() {
    window.onresize = () => {
      this.screenClick()
    }
    document.addEventListener('visibilitychange', this.setpause)
    window.vue = this
  },
  destroyed() {
    window.onresize = null
  }
}
//失去焦点
window.onblur = function() {
  if (window.vue.$route.path == '/exam/examitem') {
    clearInterval(window.vue.timer)
    if (window.vue.is_ksloading == false) {
      window.vue.is_ksloading = true
      window.vue.timer = null
      window.vue
        .$confirm('考试已暂停，您是否继续?', '提示', {
          confirmButtonText: '是',
          cancelButtonText: '否',
          type: 'warning',
          showClose: false,
          closeOnClickModal: false
        })
        .then(() => {
          window.vue.is_ksloading = false
          window.vue.countTime()
        })
        .catch(() => {
          window.vue.$router.push({ path: '/exam' })
        })
    }
  }
}
//得到焦点
// window.onfocus = function() {
//   if (window.vue.$route.path == '/exam/examitem') {
//     window.vue.countTime()
//     console.log(333);
//   }
// }
</script>
